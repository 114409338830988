import Login from '@/views/Login.vue';
import Tabs from '@/views/Tabs.vue';

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      auth: false,
    },
    component: Login,
  },
  {
    path: '/',
    meta: {
      auth: true,
    },
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'objecten',
      },
      {
        name: 'objects',
        path: 'objecten',
        component: () => import('@/views/Objects.vue'),
      },
      {
        name: 'object',
        path: 'objecten/:object',
        component: () => import('@/views/Object.vue'),
      },
      {
        name: 'resource',
        path: 'objecten/:object/:resource/:id',
        component: () => import('@/views/Resource.vue'),
        props: (route) => ({
          object: +route.params.object,
        }),
      },
      {
        path: 'contact',
        component: () => import('@/views/Contact.vue'),
      },
      {
        path: 'account',
        component: () => import('@/views/Account.vue'),
      },
    ],
  },
];
