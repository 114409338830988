<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { defineComponent, onMounted } from 'vue';
import { IonApp, IonRouterOutlet, toastController } from '@ionic/vue';

export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    onMounted(async () => {
      const isiOSSafari = navigator.userAgent.match(/(like Mac OS X|jsdom)/i) !== null;
      if (isiOSSafari && !navigator.standalone) {
        const toast = await toastController.create({
          header: 'Installeer deze app',
          message: 'Voeg toe aan uw beginscherm via <img src="/img/icon-pwa.svg" /> en kies "Zet op beginscherm".',
          buttons: [
            {
              text: 'Sluiten',
              role: 'cancel',
            },
          ],
        });
        await toast.present();
      }
    });
  },
});
</script>
