import { createApp } from 'vue';
import '@/registerServiceWorker';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonThumbnail,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonIcon,
} from '@ionic/vue';
import ionic from '@/plugins/ionic';
import router from '@/plugins/router';
import { VueAxios, axios } from '@/plugins/axios';
import auth from '@/plugins/auth';

import App from '@/App.vue';

/* Import much-used global components */

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/assets/css/variables.css';

/* Global style */
import '@/assets/css/style.css';

const app = createApp(App)
  .use(ionic, {
    swipeBackEnabled: false,
  })
  .use(router)
  .use(VueAxios, axios)
  .use(auth);

app.component('IonPage', IonPage);
app.component('IonHeader', IonHeader);
app.component('IonToolbar', IonToolbar);
app.component('IonTitle', IonTitle);
app.component('IonThumbnail', IonThumbnail);
app.component('IonContent', IonContent);
app.component('IonGrid', IonGrid);
app.component('IonRow', IonRow);
app.component('IonCol', IonCol);
app.component('IonButton', IonButton);
app.component('IonCard', IonCard);
app.component('IonCardTitle', IonCardTitle);
app.component('IonCardContent', IonCardContent);
app.component('IonList', IonList);
app.component('IonItem', IonItem);
app.component('IonLabel', IonLabel);
app.component('IonText', IonText);
app.component('IonIcon', IonIcon);

router.isReady().then(() => {
  app.mount('#app');
});
