<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <img
              alt="Lycens"
              src="/img/logo-inverted.svg"
              class="logo"
            >
          </ion-col>
        </ion-row>
        <ion-row class="ion-text-start">
          <ion-col size="12">
            <h2>Inloggen</h2>
            <form @submit.prevent="login">
              <ion-list lines="none">
                <ion-item>
                  <ion-input
                    v-model="username"
                    type="text"
                    placeholder="Gebruikersnaam"
                    required
                  />
                </ion-item>
                <ion-item>
                  <ion-input
                    v-model="password"
                    type="password"
                    placeholder="Wachtwoord"
                    required
                  />
                </ion-item>
              </ion-list>
              <ion-button
                strong
                class="ion-no-margin"
                expand="block"
                router-link="/"
                router-direction="root"
                type="submit"
              >
                <ion-label slot="start">
                  Inloggen
                </ion-label>
                <ion-icon
                  slot="end"
                  :icon="chevronForwardOutline"
                />
              </ion-button>
            </form>
            <a
              class="forgot-password"
              href="javascript:void(0)"
              @click="forgotPassword"
            >
              Wachtwoord vergeten?
            </a>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ul>
              <li>GebouwAssistent is een product van Lycens B.V.</li>
            </ul>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonButton, IonInput, alertController, loadingController,
} from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons';
import ToastMixin from '@/mixins/ToastMixin';
import AccountService from '@/services/Account';

export default defineComponent({
  components: {
    IonButton,
    IonInput,
  },
  mixins: [ToastMixin],
  setup() {
    return {
      chevronForwardOutline,
      username: null,
      password: null,
    };
  },
  methods: {
    /**
     * Login.
     */
    async login() {
      const loading = await loadingController.create();
      await loading.present();

      const params = new URLSearchParams();
      params.append('grant_type', 'password');
      params.append('username', this.username);
      params.append('password', this.password);

      try {
        const response = await this.$auth.login({
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: params.toString(),
        });

        window.localStorage.setItem('userName', response.data.userName);
      } catch {
        await this.showErrorToast('Inloggen mislukt');
      } finally {
        await loading.dismiss();
      }
    },
    /**
     * Forgot password dialog.
     */
    async forgotPassword() {
      const alert = await alertController.create({
        header: 'Wachtwoord vergeten',
        message: 'Voer hier uw gebruikersnaam in en we sturen u instructies om uw wachtwoord te resetten.',
        inputs: [
          {
            placeholder: 'Vul uw gebruikersnaam in',
            type: 'text',
          },
        ],
        buttons: [
          {
            text: 'Annuleren',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Versturen',
            handler: this.handleForgotPassword,
          },
        ],
      });
      await alert.present();
    },
    /**
     * Handle forgot password action.
     *
     * @param {Object} input
     */
    async handleForgotPassword({ 0: username }) {
      try {
        const result = await AccountService.forgotPassword(username);
        await this.showToast(result);
      } catch {
        await this.showErrorToast('Wachtwoord reset aanvraag mislukt');
      }
    },
  },
});
</script>

<style scoped>
  .logo {
    margin-top: 50px;
  }

  ion-grid {
    --ion-grid-padding: 25px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    background: var(--ion-color-dark);
    text-align: center;
  }

  h1 {
    margin-top: 10px;
    color: var(--ion-color-light);
    font-size: 20px;
  }

  h2 {
    margin: 0 0 16px;
    color: white;
    font-size: 18px;
    text-align: left;
  }

  .forgot-password {
    display: inline-block;
    padding-top: 12px;
    font-size: 13px;
    font-weight: 500;
  }

  ul {
    margin: 50px 0 0;
    padding: 0;
    color: var(--ion-color-medium);
    font-size: 13px;
    list-style: none;
  }

  ion-item {
    --background: white;
    --padding-start: 15px;

    margin-bottom: 10px;
    border-radius: 2px;
    font-size: 14px;
  }

  ion-button {
    --border-radius: 2px;

    ion-icon {
      opacity: 0.5;
    }
  }

  ion-list {
    background: transparent;
  }
</style>
