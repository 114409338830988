<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar
        slot="bottom"
        color="dark"
      >
        <ion-tab-button
          tab="objects"
          href="/objecten"
        >
          <ion-icon :icon="business" />
          <ion-label>Objecten</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="contact"
          href="/contact"
        >
          <ion-icon :icon="chatbubblesOutline" />
          <ion-label>Contact</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="account"
          href="/account"
        >
          <ion-icon :icon="personCircleOutline" />
          <ion-label>Account</ion-label>
        </ion-tab-button>
      </ion-tab-bar>

      <ion-router-outlet />
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar, IonTabButton, IonTabs, IonRouterOutlet,
} from '@ionic/vue';
import { business, chatbubblesOutline, personCircleOutline } from 'ionicons/icons';

export default {
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonRouterOutlet,
  },
  setup() {
    return {
      business,
      chatbubblesOutline,
      personCircleOutline,
    };
  },
};
</script>
