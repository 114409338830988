import { axios } from '@/plugins/axios';

/**
 * Account service.
 *
 * @return {Object}
 */
export default {
  /**
   * Forgot password.
   *
   * @param {String} username
   *
   * @return {Promise}
   */
  async forgotPassword(username) {
    const request = await axios.get(`Password/Reset/${username}`);

    return request.data;
  },
  /**
   * Change password.
   *
   * @param {String} current The current password
   * @param {String} password The new password
   *
   * @return {Promise}
   */
  async changePassword(current, password) {
    const request = await axios.post('Password/Change', {
      OldPassword: current,
      Username: window.localStorage.getItem('userName'),
      Password: password,
    });

    return request.data;
  },
};
