import { createAuth } from '@websanova/vue-auth';
import axios from 'axios';
import VueAuthDriverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm';
import VueAuthDriverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm';
import VueAuthDriverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm';
import router from '@/plugins/router';

export default createAuth({
  plugins: {
    http: axios,
    router,
  },
  drivers: {
    auth: {
      request: VueAuthDriverAuthBearer.request,
      response(res) {
        if (res.status === 401) {
          this.logout({
            makeRequest: false,
          });
        }
        return (res.data || {}).access_token;
      },
    },
    http: VueAuthDriverHttpAxios,
    router: VueAuthDriverRouterVueRouter,
  },
  options: {
    loginData: {
      url: 'Token',
      method: 'POST',
      redirect: '/',
      fetchUser: false,
    },
    logoutData: {
      redirect: '/login',
      makeRequest: false,
    },
    fetchData: {
      enabled: false,
    },
    refreshData: {
      enabled: false,
    },
    parseUserData: (data) => data,
  },
});
