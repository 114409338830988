import { toastController } from '@ionic/vue';

/**
 * Toast mixin.
 */
export default {
  data() {
    return {
      toastCssClass: 'toast-bottom',
    };
  },
  methods: {
    /**
     * Show toast.
     *
     * @param {String} message
     * @param {String} color
     */
    async showToast(message, color = undefined) {
      const toast = await toastController.create({
        message,
        color,
        duration: 2000,
        cssClass: this.toastCssClass,
      });
      await toast.present();
    },
    /**
     * Show success toast.
     *
     * @param {String} message
     */
    async showSuccessToast(message) {
      await this.showToast(message, 'success');
    },
    /**
     * Show success toast.
     *
     * @param {String} message
     */
    async showErrorToast(message) {
      await this.showToast(message, 'danger');
    },
  },
};
